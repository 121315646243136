
// https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
export type ObjectKey = string | number | symbol;
const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
  items: TItem[],
  key: K
): Record<ObjectKey, TItem[]> =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {} as Record<ObjectKey, TItem[]>
  );

export default groupBy;
