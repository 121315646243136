import "./DashBoard.css";
import ChangePw from "./components/ChangePw";
import CreateUser from "./components/CreateUser";
import GetConversation from "./components/GetConversation";
import GetFilteredConversation from "./components/GetFilteredConversation";
import GetUser from "./components/GetUsers";
import PageView from "./components/PageView";

const DashBoard = () => {
  return (
    <div className="dashBoardcontainer">
      <table className="usercontrol">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><ChangePw/></td>
            <td><CreateUser /></td>
          </tr>
        </tbody>
      </table>
      <GetUser />
      <PageView />
      <GetConversation/>
      <GetFilteredConversation/>
    </div>
  );
};

export default DashBoard;
