import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import { Constants } from "../Constants";
import BottomTab from "../components/bottom_tab/BottomTab";
import "./EduRoot.css";

const RootLayout = () => {
  return (
    <>
      <Header htitle={Constants.HEADER_TOOTHBUDDY} />
      <Outlet />
      <BottomTab />
    </>
  );
};

export default RootLayout;
