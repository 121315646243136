import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import "./MenuDropDown.css";
import { RootState } from "../../store/indexStore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import groupBy from "../admin/util/groupBy";

const MenuDropDown = (props: {
  menuStatus: Dispatch<SetStateAction<boolean>>;
}) => {
  const default_content = useSelector((state: RootState) => state.eduContent);
  let grouped = groupBy(
    default_content.filter((content) => content.display),
    "head_title"
  );

  const navigate = useNavigate();

  const treeClickHandler = (link: string) => {
    navigate(link);
    props.menuStatus(false);
  };

  return (
    <div className="MenuDropDown">
      <Box
        sx={{
          minHeight: 50,
          maxHeight: "40vh",
          flexGrow: 1,
          maxWidth: 300,
          minWidth: "200px",
          border: 1,
          borderColor: "#545454",
          borderRadius: 2,
          backgroundColor: "white",
          overflow: "auto",
          padding: "5px",
        }}
      >
        <TreeView
          aria-label="siteNav"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {Object.keys(grouped).map((grp, index) => {
            return (
              <TreeItem nodeId={"" + index} label={grp} key={grp}>
                {grouped[grp].map((subheader, subIndex) => {
                  return (
                    <TreeItem
                      key={subheader.title}
                      nodeId={index + "" + subIndex}
                      label={subheader.title}
                      onClick={() => treeClickHandler(subheader.path_var)}
                    />
                  );
                })}
              </TreeItem>
            );
          })}
          <TreeItem
            nodeId="chatbot"
            label="Ask ToothBuddyBot!"
            onClick={() => treeClickHandler("/chatbot/2")}
          ></TreeItem>
        </TreeView>
      </Box>
    </div>
  );
};

export default MenuDropDown;
