import { DialogNode } from "../../../model";
import { Graph } from "./Graph";

// type MapType = {
//   [id: string]: string;
// };

export class Constants {
  // title = button title/Node Title, content = content of nodes. After the button is pressed, the content will be displayed as the node is being visited.
  // if there is only 1 adjacent node for the current node, then the content of the current node can be omitted.
  // Summary: for Terminal node, title is not needed but content is needed
  // for pre-Terminal node, title is needed but content is not needed
  // content is needed if the current node has more than 1 neighbor. The content will be displayed so that user know what are the buttons displayed for.
  // button displayed below content are the title of adjacent nodes for the current node.
  static readonly LINKS = new Map([
    ["label.wisdom", "nodes.11"],
    [
      "label.mouthrinses",
      "It seems like you want to learn more on mouthrinse.<br/> Please click <a href='/education/mouthrinses'>here</a> for more detail",
    ],
    [
      "label.fluoride",
      "It seems like you want to learn more about fluorides.<br/> Please click <a href='/education/fluorides'>here</a> for more detail",
    ],
    ["label.painJaw", "nodes.79"],
    ["label.trauma", "nodes.9"],
    [
      "label.denture",
      "It seems like you want to learn more on denture.<br/> Please click <a href='/education/denture'>here</a> for more detail",
    ],
    [
      "label.rct",
      "It seems like you want to learn more on root canal treatment.<br/> Please click <a href='/education/rct'>here</a> for more detail",
    ],
    ["label.ulcer", "nodes.12"],
    [
      "label.xray",
      "It seems like you want to learn more on X-ray.<br/> Please click <a href='/education/xray'>here</a> for more detail",
    ],
    ["label.decayedCrown", "nodes.10"],
    ["label.gumdisease", "nodes.56"],
    [
      "label.nightguard",
      "It seems like you want to learn more on nightguard.<br/> Please click <a href='/education/nightguard'>here</a> for more detail",
    ],
    [
      "label.barodontalgia",
      "It seems like you might experiencing Barodontalgia.<br/> Please click <a href='/education/barodontalgia'>here</a> for more detail.",
    ],
    [
      "label.implant",
      "It seems like you want to learn more on tooth implant.<br/> Please click <a href='/education/implant'>here</a> for more detail",
    ],
    ["label.jawPain", "nodes.8"],
    [
      "label.toothbrushing",
      "It seems like you want to learn more on brushing tooth.<br/> Please click <a href='/education/brushing'>here</a> for more detail",
    ],
    ["label.bleeding", "nodes.7"],
    [
      "label.whitening",
      "It seems like you want to learn more on whitening.<br/> Please click <a href='/education/whitening'>here</a> for more detail",
    ],
    [
      "label.bridge",
      "It seems like you want to learn more on bridges.<br/> Please click <a href='/education/bridge'>here</a> for more detail",
    ],
    [
      "label.extraction",
      "It seems like you want to learn more on tooth extraction.<br/> Please click <a href='/education/extraction'>here</a> for more detail",
    ],
    [
      "label.flossing",
      "It seems like you want to learn more about flossing.<br/> Please click <a href='/education/flossing'>here</a> for more detail",
    ],
    ["label.sensitive", "nodes.24"],
    ["label.BleedingWisdom", "nodes.80"],
    ["label.Other","nodes.13"],
    ["label.NotDentalQn","nodes.95"]
  ]);

  static readonly NODES: DialogNode[] = [
    {
      //0
      title: "2.G",
      content:
        "Please seek further evaluation and examination by a dentist. Please call to book an appointment with your respective dental centre during office hours. (2)",
    },
    {
      //1
      title: "2.G.1",
      content:
        "You may call your respective dental centre during office hours to arrange for the next available appointment (2).",
    },
    {
      //2
      title: "2.G.2",
      content: `I'm sorry to hear of your unpleasant experience. You may call your respective dental centre during office hours to arrange for the next available appointment.
        <br/><br/>
        You may also take over-the-counter painkillers if necessary to help alleviate the pain. (3)`,
    },
    {
      //3
      title: "2.G.3",
      content: `I'm sorry to hear of your unpleasant experience. You're advised to seek dental help immediately.
        <br/><br/>
        1. During office hours, please call before you walk-in to your respective dental centre. <br/>
        2. After office hours, please visit a private dental clinic. (4)`,
    },
    {
      //4
      title: "2.G.E",
      content: `I'm sorry to hear of your unpleasant experience. You're advised to seek dental help immediately.
        <br/><br/>
        1. During office hours, please call before you walk-in to your respective dental centre.<br/>
        2. After office hours, please visit a private dental clinic (preferred) or A&E department of your nearest hospital. 
        <br/><br/>
        You may also take over-the-counter painkillers to help alleviate the pain. (4)`,
    },
    {
      //5
      title: "Dental Self Help",
      content: `I see that you need some help with your dental condition. Before we begin, do note that the chatbot is not a substitute for professional dental advice and diagnosis. If you have any doubts, please consult a medical/dental professional. If you are in severe pain or have a dental emergency, please visit the A&E department at your nearest hospital. 
        <br/><br/>
        What can I help you with?`,
    },
    {
      //6
      title: "Pain",
      content: "Where is the pain located?",
    },
    {
      //7
      title: "Bleeding",
      content: "Did you have any of the following procedures done recently?",
    },
    {
      //8
      title: "Jaw Pain",
      content: `Do you suffer from any of the following? 
      <ul>
        <li>Unable to open</li>
        <li>Unable to close</li>
        <li>Jaw swelling</li>
      </ul>
      `,
    },
    {
      //9
      title: "Trauma",
      content: "Please describe the dental trauma.",
    },
    {
      //10
      title: "Decay/Dislodged Fillings or Crown",
      content: "What is the level of pain you are experiencing?",
    },
    {
      //11
      title: "Wisdom Teeth",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //12
      title: "Ulcers",
      content:
        "How long have you had the ulcer?",
    },
    {
      //13
      title: "Other Dental Concerns",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //14
      title: "All Teeth (generalised)",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //15
      title: "Wisdom Teeth",
      content: `Did you have an extraction / surgery done recently?`,
    },
    {
      //16
      title: "Gums",
      content: `Did you have any dental treatment (scaling or cleaning) done recently?`,
    },
    {
      //17
      title: "Specific Areas (Particular Tooth / Region)",
      content: `Did you have any dental treatment done recently?`,
    },
    {
      //18
      title: "Jaw Pain",
      content: `Do you suffer from any of the following? 
      <ul>
        <li>Unable to open</li>
        <li>Unable to close</li>
        <li>Jaw swelling</li>
      </ul>
      `,
    },
    {
      //19
      title: "None of the above",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //20
      title: "Mild-Moderate Pain",
      content: `Do you have sensitivity to cold/hot stimulus (e.g. ice water, cold air, hot soup)?`,
    },
    {
      //21
      title: "Severe Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //22
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //23
      title: "No",
      content: `Do you have sensitivity when brushing?`,
    },
    {
      //24
      title: "Experiencing Tooth Sensitivity",
      content: `You might be experiencing tooth sensitivity. (1)<br/>
      Find out more about tooth sensitivity <a href='/education/sensitivity'>here</a>.
      <br/><br/>
      If you need any further assistance, please call to book an appointment with your respective dental centre during office hours. (2)`,
    },
    {
      //25
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //26
      title: "No",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //27
      title: "Post-operative pain",
      content: `Post-operative pain is expected after extraction or surgery. Please make sure you take the painkillers as prescribed.
      Click <a href='/education/extraction'>here</a> to read more about care instructions following extractions / surgery.
      <br/><br/>
      If you are in severe pain despite use of painkillers: (4)<br/>
      1. During office hours, please call before you walk-in to your respective dental centre.<br/>
      2. After office hours, please visit a private dental clinic (preferred) or A&E department of your nearest hospital.`,
    },
    {
      //28
      title: "Pain from wisdom teeth",
      content: `I'm sorry to hear of your unpleasant experience. There can be discomfort when wisdom teeth are growing out or when wisdom teeth bite on gums. Pain from wisdom teeth can be caused by a local infection of the gums or tooth decay. Brushing and keeping the area clean will usually help to relieve pain.<br/><br/>
      For moderate-mild pain, please book an appointment with the dental centres for consultation. <br/><br/>
      Click <a href='/education/wisdom'>here</a> to learn more about wisdom teeth and wisdom tooth removal. (2)`,
    },
    {
      //29
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //30
      title: "No",
      content: `Do you experience either of the following?`,
    },
    {
      //31
      title: "Post-operative pain following deep dental cleaning",
      content: `Post-operative pain following deep dental cleaning is expected. You make take over the counter painkillers to manage the pain.<br/>
      Click <a href='/education/gumdisease'>here</a> to learn more about gum diseases and treatment for gum diseases. (1)`,
    },
    {
      //32
      title: "Gum Bleeding",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //33
      title: "Gum Swelling",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //34
      title: "None of the Above",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //35
      title: "Pain associated with bleeding gums",
      content: `Pain associated with bleeding gums is a sign of gum disease.<br/>
      Click <a href='/education/gumdisease'>here</a> to learn more about gum diseases and how to care for your gums. (1)
      <br/><br/>
      Please call to book an appointment with your respective dental centres during office hours for gum treatment. (2)`,
    },
    {
      //36
      title: "It appears that you may have a dental infection.",
      content: `It appears that you may have a dental infection.<br/>
      Please call to book an appointment with your respective dental centre during office hours.<br/>
      <br/><br/>
      If you are in severe pain:
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre.<br/>
      2. After office hours, please visit a private dental clinic. (3)`,
    },
    {
      //37
      title: "Scaling/Deep Cleaning",
      content:
        "only 1 adjacent node. Content can be omitted as there is no other path in this node.",
    },
    {
      //38
      title: "Filling",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //39
      title: "Extraction / Surgery",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //40
      title: "None of the Above",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //41
      title:
        "Post-operative sensitivity or pain is expected when a deep filling was done",
      content: `Post-operative sensitivity or pain is expected when a deep filling was done. If the pain is persistent or getting worse, please return for further evaluation.<br/>
      Click <a href='/education/decay'>here</a> to learn more about tooth decay and fillings. (1)`,
    },
    {
      //42
      title: "Mild - Moderate Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //43
      title: "Severe Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //44
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //45
      title: "No",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //46
      title: "During office hours, please call before you walk-in",
      content: `During office hours, please call before you walk-in to your respective dental centre <b>immediately</b>.
      <br/><br/>
      After office hours, please visit the A&E department of your nearest hospital <b>immediately</b>.`,
    },
    {
      //47
      title:
        "It appears that you may have a temporomandibular joint disorder (TMD).",
      content: `It appears that you may have a temporomandibular joint disorder (TMD).<br/>
      Click <a href='/education/tmd'>here</a> to learn more about TMD.<br/>
      <br/><br/>
      Please call to book an appointment with your respective dental centre during office hours for a consultation. (2)`,
    },
    {
      //48
      title: "Pain can be multifactorial",
      content: `Pain can be multifactorial and can have several manifestation. Please visit a medical doctor or dentist to evaluate and diagnose your condition. (2)`,
    },
    {
      //49
      title: "Extraction / Surgery",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //50
      title: "Scaling / Filling",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //51
      title: "None of the Above",
      content: `When do you have bleeding?`,
    },
    {
      //52
      title: "Minor bleeding is expected after such extraction",
      content: `Minor bleeding is expected after such extraction / surgery. Rinsing or spitting excessively will lead to continued bleeding.<br/>
      If you are bleeding profusely, place a gauze at the extraction / surgery site and apply pressure by biting on it for 30 mins continuously. (1)<br/>
      <br/><br/>
      If you experience persistent symptoms such as pain, bleeding or swelling or fever, please return to the dental centre during office hours or approach the A&E department at your nearest hospital. (4)
      <br/><br/>
      Read more about post-operative instructions in our <a href="/education/extraction">Extraction</a> Education Module. `,
    },
    {
      //53
      title: "Minor bleeding is expected after scaling or deep fillings.",
      content: `Minor bleeding is expected after scaling or deep fillings. Rinsing excessively will lead to continued bleeding.<br/>
      If you are bleeding profusely, place a gauze at the site of bleeding and apply pressure for 30 mins. (1)<br/>
      <br/><br/>
      If you experience persistent symptoms such as pain, bleeding or swelling, please return to the dental centre during office hours or approach the A&E department at your nearest hospital. (4)`,
    },
    {
      //54
      title: "Bleeding while brushing / flossing",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //55
      title: "Bleeding unprovoked / spontaneous",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //56
      title: "Bleeding gums is a sign of gum disease.",
      content: `Bleeding gums is a sign of gum disease. Click the links below to learn more about gum diseases and how to care for your gums. Please also call to book an appointment with your respective dental centre during office hours for gum treatment. (2)<br/>
      <br/><br/>
      Click <a href='/education/gumdisease'>here</a> to read about gum diseases.
      <br/><br/>
      Click <a href='/education/brushing'>here</a> to read about brushing.
      <br/><br/>
      Click <a href='/education/flossing'>here</a> to read about flossing.`,
    },
    {
      //57
      title: "I hit My Jaw",
      content: `Do you suffer from any of the following?
      <ul>
      <li>jaw deviation</li>
      <li>jaw bruising</li>
      <li>change in bite (unable to bite normally)</li>
      <li>limited jaw movement (unable to open/close)</li>
      </ul>`,
    },
    {
      //58
      title: "I Knocked/Broke my Teeth",
      content: `Do you suffer from any of the following?`,
    },
    {
      //59
      title: "I Cut My Gums / Lip",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //60
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //61
      title: "No",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //62
      title: "My Tooth is Broken",
      content: `Is there any pain associated with the broken tooth?`,
    },
    {
      //63
      title: "My Tooth is Shifted/Shaky/Loose",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //64
      title: "My Tooth has dropped out",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //65
      title: "None of the Above",
      content: `What is the level of pain you are experiencing?`,
    },
    {
      //66
      title: "No Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //67
      title: "Mild-Moderate Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //68
      title: "Severe Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //69
      title: "If you are able to find the broken fragment",
      content: `If you are able to find the broken fragment, keep it and bring it with you.<br/>
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre.<br/>
      2. After office hours, please consider visiting a private dental clinic or walk-in to your respective dental centre the next working day.
      <br/><br/>
      You may also take over-the-counter painkillers if necessary to help alleviate the pain. (3)`,
    },
    {
      //70
      title:
        "During office hours, please call before you walk-in to your respective dental centre",
      content: `If you are able to find the broken fragment, keep it and bring it with you.
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre.<br/>
      2. After office hours, please consider visiting a private dental clinic or walk-in to your respective dental centre the next working day.
      <br/><br/>
      You may also take over-the-counter painkillers if necessary to help alleviate the pain. (3)`,
    },
    {
      //71
      title: "Walk-in to the A&E",
      content: `Walk-in to the A&E department of your nearest hospital.
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre.<br/>
      2. After office hours, please walk-in to the A&E department of your nearest hospital.`,
    },
    {
      //72
      title: "Pick up the tooth by the crown if you can find it",
      content: `✅ Pick up the tooth by the crown if you can find it.<br/>
      ⛔️ Do NOT touch the root of the tooth.<br/>
      ✅ Lightly rinse the tooth in milk/saline (preferably) or tap water.<br/>
      ⛔️ Do NOT scrub the root surface of the tooth.<br/>
      <br/><br/>
      Then, do one of the following:
      <ol>
      <li>Put the tooth back in its place and gently bite together to keep it in place</li>
      <li>Place the tooth in a bag/cup of milk or saline</li>
      <li>If milk or saline is not available, keep the tooth in the mouth between the cheeks and the gums.</li>
      </ol>
      ⛔️ Do NOT soak the tooth in water.
      <br/><br/>
      During office hours, walk-in to your respective dental centre <b>immediately</b>.
      <br/><br/>
      After office hours, please walk-in to a private dental clinic (preferred) or A&E department of your nearest hospital <b>immediately</b>. (4)`,
    },
    {
      //73
      title: "No Pain",
      content:
        "only 1 adjacent node. Content can be omitted as there is no other path in this node.",
    },
    {
      //74
      title: "Mild-Moderate Pain",
      content:
        "only 1 adjacent node. Content can be omitted as there is no other path in this node.",
    },
    {
      //75
      title: "Severe Pain",
      content:
        "only 1 adjacent node. Content can be omitted as there is no other path in this node.",
    },
    {
      //76
      title: "It is a common misconception that all wisdom teeth ",
      content: `It is a common misconception that all wisdom teeth needs to be removed. Some wisdom teeth are functional and useful.<br/>
      Click <a href='/education/wisdom'>here</a> to learn more about wisdom teeth and wisdom tooth removal.
      <br/><br/>
      If in doubt, please call to book an appointment with your respective dental centre during office hours. (1)`,
    },
    {
      //77
      title: "Click here to learn more about dental ulcers. Terminal Node",
      content: `Click <a href='/education/ulcer'>here</a> to learn more about dental ulcers.
      <br/><br/>
      If the ulcers do not heal in 2 weeks, please call to book an appointment with your respective dental centre during office hours. (1)`,
    },
    {
      //78
      title: "Learn more about various dental conditions and treatment",
      content: `Learn more about various dental conditions and treatment in our education modules in the "Education" tab of the app.
      <br/><br/>
      If you have any other dental concerns that has not been addressed, please make an appointment with your respective dental centre. (1)`,
    },
    {
      //79
      title: "node6, 8 and 13 (Pain, JawPain and Other)",
      content: `Please choose the following 3 options below`,
    },
    {
      //80
      title: "node7, 11 and 13 (bleeding, Wisdom, Other)",
      content: `Please choose the following 3 options below`,
    },
    {
      //81
      title: "Mild - Moderate Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //82
      title: "Severe Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //83
      title: "Post-operative pain Terminal Node",
      content: `Post-operative pain is expected after extraction or surgery. Please make sure you take the painkillers as prescribed. Click <a href="/education/extraction">here</a> to read more about care instructions following extractions / surgery. (1)`,
    },
    {
      //84
      title: "Severe Pain Terminal Node",
      content: `I am sorry to hear of your unpleasant experience. If you are in severe pain despite use of painkillers:
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre. 
      <br/>
      2. After office hours, please visit a private dental clinic (preferred) or A&E department of your nearest hospital. (4)`,
    },
    {
      //85
      title: "Yes",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //86
      title: "No",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //87
      title: "Mild-Moderate Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //88
      title: "Mild-Moderate Pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //89
      title: "Severe pain",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //90
      title: "Severe pain Filling Terminal Node",
      content: `I am sorry to hear of your unpleasant experience. The nerve in the tooth, otherwise known as the pulp, may become affected and inflamed, resulting in pulpitis. If you are in severe pain despite use of painkillers:
      <br/><br/>
      1. During office hours, please call before you walk-in to your respective dental centre
      <br/><br/>
      2. After office hours, please visit a private dental clinic (preferred) or A&E department of your nearest hospital.
      <br/><br/>
      Click <a href='/education/decay#untreated'>here</a> to learn more about pulpitis (1)`,
    },
    {
      //91
      title: "Ulcer Less than 2 weeks",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //92
      title: "Ulcer more than 2 weeks",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //93
      title: "Click here to learn more about dental ulcers. Terminal Node",
      content: `Click <a href='/education/ulcer'>here</a> to learn more about dental ulcers.
      <br/><br/>
      Please call to book an appointment with your respective dental centre during office hours. (2)`,
    },
    {
      //94
      title: "Facial Swelling",
      content: `only 1 adjacent node. Content can be omitted as there is no other path in this node.`,
    },
    {
      //95
      title: "options for unknown",
      content: `Please ask dental related question. Alternatively, you can select the options below.`,
    },
  ];

  static readonly ADJACENCYLIST = [
    [], //0
    [], //1
    [], //2
    [], //3
    [], //4
    [6, 7, 8, 9, 10, 11, 12, 13], //5
    [14, 15, 16, 17, 18, 19], //6
    [49, 50, 51], //7
    [44, 45], //8
    [57, 58, 59], //9
    [73, 74, 75, 94], //10
    [76], //11
    [91,92], //12
    [78], //13
    [20, 21], //14
    [25, 26], //15
    [29, 30], //16
    [37, 38, 39, 40], //17
    [44, 45], //18
    [48], //19
    [22, 23], //20
    [4], //21
    [24], //22
    [85,86], //23
    [], //24
    [27], //25
    [87,75], //26
    [], //27
    [], //28
    [31], //29
    [32, 33, 34], //30
    [], //31
    [35], //32
    [36], //33
    [0], //34
    [], //35
    [], //36
    [31], //37
    [88,89], //38
    [81,82], //39
    [42, 43], //40
    [], //41
    [2], //42
    [4], //43
    [46], //44
    [47], //45
    [], //46
    [], //47
    [], //48
    [52], //49
    [53], //50
    [54, 55], //51
    [], //52
    [], //53
    [56], //54
    [0], //55
    [], //56
    [60, 61], //57
    [62, 63, 64, 65], //58
    [4], //59
    [4], //60
    [0], //61
    [66, 67, 68], //62
    [3], //63
    [72], //64
    [73, 74, 75], //65
    [69], //66
    [70], //67
    [71], //68
    [], //69
    [], //70
    [], //71
    [], //72
    [1], //73
    [2], //74
    [3], //75
    [], //76
    [], //77
    [], //78
    [6, 8, 13], //79
    [7, 11, 13], //80
    [83], //81
    [84], //82
    [], //83
    [], //84
    [35], //85
    [0], //86
    [28], //87
    [41], //88
    [90], //89
    [], //90
    [77], //91
    [93], //92
    [], //93
    [4], //94
    [6, 7, 8, 9, 10, 11, 12, 13], //95
  ];

  static readonly GRAPH = new Graph({ adjacencyList: Constants.ADJACENCYLIST });
}
