import * as React from "react";
import "./BottomTab.css";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import { useLocation, useNavigate } from "react-router-dom";

export default function SimpleBottomNavigation() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(pathname);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <BottomNavigation
        sx={{ position: "fixed", bottom: 0, width: 1.0 }}
        showLabels
        value={pathname}
        onChange={(event, newValue: string) => {
          // console.log(`pathname = ${pathname}`);
          // console.log(value)
          if (newValue === "/") navigate("/");
          if (newValue === "/chatbot/1") navigate("/chatbot/1");
          if (newValue === "/chatbot/2") navigate("/chatbot/2");
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          value="/"
          label="Education"
          icon={<SchoolTwoToneIcon />}
        />
        {/* <BottomNavigationAction
          value="/chatbot/1"
          label="ToothBuddy"
          icon={<SmartToyTwoToneIcon />}
        /> */}
        <BottomNavigationAction
          value="/chatbot/2"
          label="ToothBuddy"
          icon={<SmartToyTwoToneIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
