import Header from "../components/adminheader/Header";
import "./AdminRoot.css";
import { Outlet } from "react-router-dom";

const AdminRoot = () => {
  return (
    <div className="admincontainer">
      <Header htitle="Admin" />
      <Outlet />
    </div>
  );
};

export default AdminRoot;
