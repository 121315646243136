import { Link } from "react-router-dom";
import { ToasterProp } from "../../model";
import "./MiniCoaster.css";
import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CoasterButton = styled(Button)({
  marginTop: "2px",
  textAlign: "center",
  borderRadius: "8px",
  border: "2px solid",
  overflow: "clip",
  width: "18vh",
  color: "#545454",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  '&:hover': {
    backgroundColor: 'yellow',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const Mini_Coaster = (props: ToasterProp) => {
  return (
    <Link to={props.pathVar}>
      <figure>
        <div className="circletag">
          <img src={props.picURL} alt={props.title} />
        </div>
        <figcaption className="Coaster">
          <CoasterButton
            className="miniCoasterButton"
            variant="outlined"
          >
            <span >
              <strong>{props.title}</strong>
            </span>
          </CoasterButton>
        </figcaption>
      </figure>
    </Link>
  );
};

export default Mini_Coaster;
