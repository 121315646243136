import { useParams } from "react-router-dom";
import "./EduTemplate.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/indexStore";
import { HTMLReactParserOptions } from "html-react-parser/lib/types";
import { HashLink } from "react-router-hash-link";
import parse from "html-react-parser";
import { useEffect } from "react";
import axios from "axios";
import { Constants } from "../../Constants";

  // use for replacing <a> to Link so that request is sent to client instead of server
  const options: HTMLReactParserOptions = {
    replace(domNode:any) {
      if (domNode.name=='a' &&  domNode.attribs.style==='text-decoration:none') {
        // return <HashLink to ={domNode.attribs.href}> {domNode.children[0].children[1].data}</HashLink>
        return (
        <HashLink to ={domNode.attribs.href} className="caq" style={{textDecoration:"none"}}> 
          <div className="caq"><span className="plus">+</span>
          {domNode.children[0].children[1].data}
          </div>
        </HashLink>
        )
      }
    },
  };

const EduTemplate = () => {
  const params = useParams();
  const pathVar = params.var;
  const contents = useSelector((state: RootState) => state.eduContent);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
    axios.post<null>(Constants.EDU_PGVIEW, {path:pathVar});
  },[pathVar])

  const content = contents.find((x) => {
    const splitArray = x.path_var.split("/");
    return pathVar === splitArray[splitArray.length - 1];
  });

  if (content) {
    return (
      <div className="eduTemplateContainer">
        <h1 className="eduTemplateH1">{content.title}</h1>
        {/* <div
          className="eduTemplateHTMLContainer"
          dangerouslySetInnerHTML={{ __html: content.content_html }}
        /> */}
        <div className="eduTemplateHTMLContainer">
          {parse(content.content_html,options)}
        </div>
      </div>
    );
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Page not Found.</p>
    </div>
  );
};

export default EduTemplate;
