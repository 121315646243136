import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Constants } from "../../../Constants";
import { PageViewCount } from "../../../model";
import "./PageView.css";
import { debounce } from "lodash";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const datetostr = (d: Date) => {
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(d.getDate()).padStart(2, "0")}`;
};

const setPageViewCount = (
  d1: string,
  d2: string,
  setPgView: (arr: PageViewCount[]) => void
) => {
  axios
    .get<PageViewCount[]>(Constants.URL_ADMIN_GETPAGEVIEW, {
      params: { d1, d2 },
    })
    .then((res) => {
      setPgView(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const PageView = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  const tostr = datetostr(now);
  const last = new Date();
  const [days, setDays] = useState(30);
  const [d2, setD2] = useState(tostr);
  const [pgView, setPgView] = useState<PageViewCount[]>([]);
  last.setDate(last.getDate() - days);
  const d1 = datetostr(last);

  const debounceLoad = useCallback(
    debounce(
      (d1: string, d2: string, setPgView: (arr: PageViewCount[]) => void) => {
        setPageViewCount(d1, d2, setPgView);
      },
      800
    ),
    []
  );

  useEffect(() => {
    setPageViewCount(d1, d2, setPgView);
  }, []);

  useEffect(() => {
    return () => {
      debounceLoad.cancel();
    };
  }, [debounceLoad]);

  const dayhandler = (numday: number) => {
    const day1 = new Date();
    day1.setDate(day1.getDate()-numday + 1 );
    debounceLoad(datetostr(day1), d2, setPgView);
    setDays(numday);
  };

  return (
    <div>
      <h3>Page Views for last {days} days</h3>
      <input
        type="number"
        min={1}
        value={days}
        onChange={(e) =>
          dayhandler(Number.isNaN(+e.target.value) ? 1 : +e.target.value)
        }
        style={{ width: "50px" }}
      />
      {pgView.length == 0 && <div>`No pagesview for the last ${days} days`</div>}
      {pgView.length > 0 && (
        <table className="pgview">
          <thead>
            <tr>
              <th>Page</th>
              <th>View Count</th>
            </tr>
          </thead>
          <tbody>
            {pgView.map((v) => {
              return (
                <tr key={v.path}>
                  <td>{v.path}</td>
                  <td>{v.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PageView;
