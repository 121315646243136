import { Tree } from "react-tree-graph";
import "./DialogMap.css";
import { Graph } from "../chatbot_kit/dialog_flowchart/Graph";
import { Tooltip } from "react-tooltip";
import { Dispatch, useState } from "react";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { Link } from "react-router-dom";
import store from "../../store/indexStore";

interface DataNode {
  name: string | number;
  label: React.ReactElement;
  children?: DataNode[];
}

export const LabelComp: React.FC<{
  cont: string;
  labelName: string;
  contFunc: Dispatch<string>;
}> = (props) => {
  return (
    <text
      data-tooltip-id="my-tooltip"
      onMouseEnter={() =>
        props.contFunc(
          "Nodes." +
            props.labelName +
            "<br/><br/>" +
            props.cont +
            "<br/><br/>Click <a href='/tbadmin/nodes/" +
            props.labelName.split(" ")[0] +
            "'>here</a> to edit node"
        )
      }
    >
      {props.labelName}
    </text>
  );
};

// use for replacing <a> to Link so that request is sent to client instead of server
// somehow instanceof Element always return false, so have to set it domNode to any for it to work
const options: HTMLReactParserOptions = {
  replace(domNode: any) {
    if (domNode.name === "a") {
      return (
        <Link to={domNode.attribs.href} target="_blank">
          {domNode.children[0].data}
        </Link>
      );
    }
  },
};

const DialogMap = () => {
  // useState hook for setting tooltip content
  const [cont, setCont] = useState("");
  const graph = new Graph({ adjacencyList: store.getState().node.order });
  const length = graph.numberNodes;
  // all nodes
  const allNodes = [...Array(length).keys()];
  // nodes with no neighbor
  const terminalNodes = new Set<number>();
  // nodes with neighbor and inside adjaceny list
  const midnTerminalNodes = new Set<number>();

  // getting terminal nodes and midenodes
  store.getState().node.order.forEach((x, index) => {
    if (x.length == 0) terminalNodes.add(index);
    else x.forEach((y) => midnTerminalNodes.add(y));
  });

  // nodes not inside adjacency list
  const starterNodes = new Set<number>();
  const midNodes = new Set<number>();

  allNodes.forEach((x) => {
    if (!midnTerminalNodes.has(x)) starterNodes.add(x);
    else if (!terminalNodes.has(x)) midNodes.add(x);
  });

  // create right data structure from starterNode to send as prop to tree map
  const genData = (nodes: number, adjacencyList: number[][]): DataNode => {
    if (adjacencyList[nodes].length === 0) {
      const dataNode: DataNode = {
        name: store.getState().node.dialog[nodes].title,
        label: (
          <LabelComp
            cont={store.getState().node.dialog[nodes].content}
            labelName={nodes + " " + store.getState().node.dialog[nodes].title}
            contFunc={setCont}
          />
        ),
      };
      return dataNode;
    }
    const dataNode: DataNode = {
      name: store.getState().node.dialog[nodes].title,
      label: (
        <LabelComp
          cont={store.getState().node.dialog[nodes].content}
          labelName={nodes + " " + store.getState().node.dialog[nodes].title}
          contFunc={setCont}
        />
      ),
      children: [],
    };
    for (let x of adjacencyList[nodes]) {
      dataNode.children?.push(genData(x, adjacencyList));
    }
    return dataNode;
  };

  return (
    <div className="dialogmap">
      <Tree
        data={genData(5, store.getState().node.order)}
        height={4000}
        width={1500}
        labelProp="label"
      />
      <Tooltip
        id="my-tooltip"
        place="bottom"
        clickable
        style={{ maxWidth: "50vw" }}
      >
        <span>{parse(cont, options)}</span>
      </Tooltip>
    </div>
  );
};

export default DialogMap;
