import axios from "axios";
import "./Login.css";
import { Constants } from "../../Constants";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import store from "../../store/indexStore";
import { adminSlice } from "../../store/admin";

interface Credentials {
  emailUsername: string;
  pw: string;
}

interface LoginRes {
  result: string;
  token: string;
  role:string;
}

const Login = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  
  const [userName, setUserName] = useState("");
  const [pw, setPw] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    // if no error, mean login successful
    try {
      const response = await axios.post<LoginRes>(Constants.URL_ADMINLOGIN, {
        email: userName,
        pw: pw,
      });
      store.dispatch(
        adminSlice.actions.setToken({ loginNick: userName, isLoggedIn: true, role: response.data.role })
      );
      localStorage.setItem("tbau", response.data.token);
      if (response.data.token) navigate("/tbadmin/dashboard");
    } 
    // fail login (status is 401) and become an error
    catch (error: unknown) {
      console.log(error);
      setLoginError("wrong email or/and password");
    }
  };

  

  return (
    <div className="logincontainer">
      <form onSubmit={handleSubmit}>
        <label htmlFor="username" style={{ display: "block" }}>
          Username
        </label>
        <input
          id="username"
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          required
        />

        <label htmlFor="pw" style={{ display: "block" }}>
          Password
        </label>
        <input
          id="pw"
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          required
        />

        <div className="button_submit">
          <button type="submit">
            {navigation.state === "submitting" ? "Logging in" : "login"}
          </button>
        </div>
      </form>
      {loginError != "" && loginError}
      <br/>
      <span>Viewing experience is not optimised for mobile device.</span>
      <span>For optimal viewing experience, pls use monitor</span>

    </div>
  );
};

export default Login;


