import "./PostCreator.css";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import parse from "html-react-parser";
import { HTMLReactParserOptions } from "html-react-parser/lib/types";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { Constants } from "../../../Constants";
import { IEduHeader } from "../../../model";
import { getContents } from "../../..";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const template = `
<span class="eduTemplateHTMLContainer">Commonly Asked Questions</span>
  <a style="text-decoration:none" href="#1"><div class="caq"><span class="plus">+</span>Header 1</div></a>
  <a style="text-decoration:none" href="#2"><div class="caq"><span class="plus">+</span>Header 2</div></a>
  <a style="text-decoration:none" href="#3"><div class="caq"><span class="plus">+</span>Header 3</div></a>
  <a style="text-decoration:none" href="#4"><div class="caq"><span class="plus">+</span>Header 4</div></a>
  <br/>
  <span class="eduTemplateHTMLContainer" >Header 1</span>
  <div class="contentP" id="1">
    <p class="p1image">
      <img src="/images/barodontalgia.png" height=250/>
    </p>
    <p>
      type your content here... you can copy this block. 
    </p>
    <p>
      <ul>
        <li>Tooth <b>pain</b> caused by a <b>change in atmospheric pressure</b></li>
        <li>Usually happens to people who fly or dive</li>
        <li>Symptom of a pre-existing oral disease</li>
        <li>Healthy teeth will not experience barodontalgia</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Header 2</span>
  <div class="contentP" id="2">
    <p>
      <p>
        type your content here... you can copy this block. 
      </p>
      Barodontalgia is caused by:
      <ul>
        <li>Tooth decay</li>
        <li>Defective fillings</li>
        <li>Cracked tooth</li>
        <li>Nasal congestion</li>
        <li>Inflammation of the ear</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Header 3</span>
  <div class="contentP" id="3">
    <p>
      <u>On descent during diving</u>:
      <ul>
        <li>Any existing air space (caused by decay or defective fillings) fails to adjust its internal pressure to the external pressure, causing pain</li>
      </ul>
      <u>On ascent during diving</u>:
      <ul>
        <li>During descent, compressed air slowly enters the teeth that have defective restorations but cannot escape quickly enough during ascent</li>
        <li>As the diver’s depth decreases, pressure builds up within the tooth due to expansion of the trapped air leading to severe pain and sometimes even fractures</li>
      </ul>
    </p>
    <p>
      <u>On ascent when flying</u>:
      <ul>
        <li>The external pressure decreases while the volume of gases increase in a compromised tooth</li>
        <li>Existing air spaces in defective restorations, recurrent decay or cracked tooth can cause pain</li>
        <li>Crowns may even be dislodged if the cement layer under the crowns has micro air bubbles</li>
      </ul>
    </p>
  </div>
  <span class="eduTemplateHTMLContainer" >Header 4</span>
  <div class="contentP" id="4">
    <p>
      type your content here... you can copy this block. 
    </p>
    <p>
      <ol>
        <li>Maintain good oral health</li>
        <li>Go for regular dental check ups (to check if you have tooth decay or damaged fillings)</li>
        <li>Avoid flying/diving for the next 7 days after a surgical procedure / tooth extractions</li>
        <li>Avoid flying/diving for the next 24 hours after a dental procedure requiring anaesthetic</li>
      </ol>
    </p>
  </div>
`;

// use for replacing <a> to Link so that request is sent to client instead of server
const options: HTMLReactParserOptions = {
  replace(domNode: any) {
    if (
      domNode.name == "a" &&
      domNode.attribs.style === "text-decoration:none"
    ) {
      // return <HashLink to ={domNode.attribs.href}> {domNode.children[0].children[1].data}</HashLink>
      return (
        <HashLink
          to={domNode.attribs.href}
          className="caq"
          style={{ textDecoration: "none" }}
        >
          <div className="caq">
            <span className="plus">+</span>
            {domNode.children[0].children[1].data}
          </div>
        </HashLink>
      );
    }
  },
};

const PostCreator= (props:{onCreate: () => void}) => {
  const [headers, setHeaders] = useState<IEduHeader[]>([
    { id: 1, name: "Oral Health Wellness" },
    { id: 2, name: "Dental Conditions" },
    { id: 3, name: "Dental Treatment" },
  ]);
  const [header, setHeader] = useState("Oral Health Wellness");
  const [picUrl, setPicUrl] = useState("/dental_icons/dentalFFI.png");
  const [url, setUrl] = useState("/education/");
  const [title, setTitle] = useState("");

  const [content, setContent] = useState(template);

  useEffect(() => {
    axios
      .get<IEduHeader[]>(Constants.EDU_CONTENT_HEADER)
      .then((res) => {
        setHeaders(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onCreateHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    
    try {
      const result = await axios.post(Constants.URL_ADMIN_CREATEPOST, {
        title: title,
        pic_url: picUrl,
        path_var: url,
        content: content,
        eduheader: headers.find((x) => x.name === header)?.id ?? 1,
        oldTitle: title,
      });
      window.alert("Post Created")
      await getContents();
      axios
      .get<IEduHeader[]>(Constants.EDU_CONTENT_HEADER)
      .then((res) => {
        setHeaders(res.data);
        props.onCreate();
      })
      .catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error)
      window.alert(`error in creating post: ${error}`)
    }
  };

  return (
    <div className="editorContainer">
      <div className="headerTitle">
        <h3>Create Post Editor/Preview</h3>
      </div>
      <div className="editorpreview">
        <div className="editor">
          <form onSubmit={onCreateHandler}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="grp">Group:</label>
                  </td>
                  <td>
                    <select
                      name="grp"
                      id="grp"
                      onChange={(e) => setHeader(e.target.value)}
                    >
                      {headers.map((h) => (
                        <option key={h.id} value={h.name}>
                          {h.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="urlpic">Pic Url:</label>
                  </td>
                  <td>
                    <input
                      id="urlpic"
                      type="text"
                      value={picUrl}
                      onChange={(e) => setPicUrl(e.target.value)}
                      size={30}
                      minLength={3}
                      placeholder="/dental_icons/dentalFFI.png"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="url">Url:</label>
                  </td>
                  <td>
                    <input
                      id="url"
                      type="text"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      size={30}
                      minLength={3}
                      placeholder="/education/title"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="title">Title:</label>
                  </td>
                  <td>
                    <input
                      id="title"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      size={30}
                      minLength={3}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <Textarea
              minRows={30}
              placeholder="Minimum 3 rows"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <button type="submit">Create</button>
          </form>
        </div>
        <div className="preview">
          {parse(
            `<h1 className="eduTemplateH1">${title}</h1>` + content,
            options
          )}
        </div>
      </div>
    </div>
  );
};

export default PostCreator;
